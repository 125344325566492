@import url("https://use.typekit.net/vmo5cqg.css");

body {
  background: #fff;
  color: #283037;
  font-family: "museo-sans";
  font-size: 16px;
  font-weight: 400;
}

a {
  color: #396af1;
}

/* Type */
a {
  text-decoration: none;
}

h1 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 900;
  margin: 20px 0;
}
@media only screen and (max-width: 1080px) {
  h1 {
    font-size: 30px;
    line-height: 36px;
  }
}

h1.lead {
  font-size: 50px;
  line-height: 50px;
  letter-spacing: -1.5px;
}

h4.styled {
  font-size: 20px;
  font-weight: 900;
  letter-spacing: -1px;
  margin: 20px 0;
  text-transform: uppercase;
}

h2.styled {
  font-size: 28px;
  font-weight: 900;
  letter-spacing: -2.5px;
  text-transform: uppercase;
}

h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 900;
  margin: 20px 0;
}

h2.sublead {
  font-size: 20px;
  line-height: 24px;
}

h3 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 900;
  margin: 20px 0;
}

h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
}

p {
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;
  margin: 20px 0;
}

.buttons {
  margin: 60px 0 0 0;
  width: 100%;
}

.button {
  color: #fff;
  background: #396af1;
  border: none;
  outline: none;
  font-weight: 900;
  letter-spacing: -1;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.button:hover {
  background: #3558b8;
  transition: background 0.4s ease;
}

.primary {
  font-size: 24px;
  padding: 15px 30px;
}

.regular {
  font-size: 22px;
  padding: 10px 30px;
  margin: 0;
}

.alternate {
  font-size: 24px;
  padding: 15px 30px;
  background: #6b7c93;
  margin-left: 20px;
}

.buttons .buttonbreak {
  display: none;
}

@media only screen and (max-width: 500px) {
  .buttons a {
    margin: 0 auto 0 auto;
    width: 70%;
    display: block;
  }

  .buttons .buttonbreak {
    display: block;
    width: 100%;
    height: 20px;
  }

  .alternate {
    font-size: 20px;
    padding: 15px 30px;
  }
}

.button .authicon {
  margin-right: 12px;
  width: 20px;
  float: left;
}

@media only screen and (max-width: 500px) {
  .button .authicon {
    margin-right: 12px;
    width: 20px;
  }
}

.button.login {
  margin-bottom: 10px;
  width: 330px;
}

.button.login.minimal {
  background: transparent;
  padding: 0;
  font-size: 16px;
  color: #396af1;
  font-weight: 400;
  margin: 30px 0 0 20px;
}

.button.google {
  background-color: #f1396a;
}

.button.facebook {
  background-color: #4267b2;
}

.button.google:hover,
.button.facebook:hover {
  opacity: 1;
}

.clear {
  clear: both;
}

/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */
/* AUTH PAGE  Styles */
/* AUTH PAGE  Styles */
/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */

.authswitchbox {
  width: 340px;
  margin: 30px auto 0 auto;
  text-align: center;
}

.authswitchbox .title a {
  font-size: 16px;
  color: #396af1;
}

.authbox {
  width: 340px;
  margin: 100px auto 0 auto;
  padding: 40px 50px;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.authbox .title {
  text-align: left;
  width: 300px;
  font-size: 30px;
  font-weight: 900;
  letter-spacing: -1px;
  margin: 0 0 30px 0;
}

.authbox .prompt {
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  margin: 0 0 20px 0;
}

.authbox .divider {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
  margin: 30px 0 40px 0;
}

input.login {
  width: 300px;
  display: block;
  outline: none;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 15px 10px 15px;
  border-radius: 3px;
  margin: 0 auto 10px auto;
  appearance: none;
  -webkit-appearance: none;
}

.errorbox {
  width: 330px;
  background: #c039f1;
  border-radius: 3px;
  border: 2px solid #c039f1;
  margin: 0 auto 20px auto;
}

.erroricon {
  float: left;
  height: 100%;
  width: 30px;
  margin: 10px 0 10px 10px;
  color: #fff;
}

.errormessage {
  float: left;
  text-align: left;
  width: 240px;
  margin: 10px;
  padding: 0;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 600;
}
/* RESPONSIVE FOR LOGIN BOX */
@media only screen and (max-width: 500px) {
  .authswitchbox {
    width: 100%;
    text-align: left;
  }

  .authswitchbox .title a {
    margin: 0 0 10px 20px;
  }

  .authbox {
    width: 100%;
    margin: 40px auto 0 auto;
    text-align: left;
    padding: 0;
    box-shadow: none;
  }

  .authbox .title {
    text-align: left;
    width: 300px;
    margin: 0 20px 10px 20px;
  }
  .authbox .prompt {
    margin: 0 20px 20px 20px;
  }

  .authbox .divider {
    width: 50%;
    margin: 30px 0 40px 20px;
  }

  input.login {
    width: 240px;
    display: block;
    margin: 10px 0 0 20px;
  }

  .button.login {
    display: block;
    padding: 10px 15px;
    width: auto;
    margin: 10px 0 0 20px;
  }

  .errorbox {
    width: 240px;
    margin: 10px 0 20px 20px;
  }

  .errormessage {
    width: 180px;
  }
}

/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */
/* MANAGEMENT PANEL  Styles */
/* MANAGEMENT PANEL  Styles */
/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */

/* Hotel Page Specifics */
.hotel-image {
  width: 300px;
  height: 200px;
  margin: 0 10px 10px 0;
  float: left;
  background-size: cover;
}
@media only screen and (max-width: 500px) {
  .hotel-image {
    width: 100%;
    height: 250px;
  }
}

/* Admin panel */
.management .mainpanel {
  margin: 44px 0 0 0;
  padding: 12px;
}

.management .subpanel {
  width: 30%;
  margin-left: 10px;
  float: left;
}

@media only screen and (max-width: 500px) {
  .management .subpanel {
    width: 100%;
    margin: 0;
  }
}

.management .navbar {
  width: 100%;
  height: 44px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  border-bottom: 1px solid #eee;
  background: #fff;
  margin: 0 auto;
}
.management .navbar .branding {
  float: left;
  margin-left: 12px;
  margin-top: 12px;
}

.management .navbar .toplinks {
  float: right;
  list-style: none;
  margin-right: 12px;
}

.management .navbar .toplinks .linkitem {
  position: relative;
  float: left;
}

.management .navbar .toplinks .linkitem .link {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  margin-left: 35px;
  text-decoration: none;
  letter-spacing: -0.5px;
  line-height: 44px;
  color: #283037;
}

.management .navbar .toplinks .linkitem .link:hover {
  color: #396af1;
}

.management .listaction {
  color: #396af1;
}

.management .mainpanel h3 {
  margin: 0;
  padding: 0;
}

.management .tripexpertdestinationlist .item {
  margin: 0 0 10px 0;
}

.management .tripexpertdestinationlist .item .detail {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
}

.management .tripexpertdestinationlist .item .name {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}

.management .choose {
  outline: none;
  border: none;
  background: #6b7c93;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  border-radius: 3px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 5px 0 0;
  padding: 5px 5px 3px 5px;
}

.management .addform .detail {
  padding: 6px 8px;
  border-radius: 5px;
  border: 1px solid #6b7c93;
  outline: none;
  margin: 0 0 8px 0;
  width: 400px;
}

@media only screen and (max-width: 500px) {
  .management .addform .detail {
    width: 100%;
  }
}

.management .addform .add {
  padding: 6px 8px;
  border-radius: 5px;
  border: 1px solid #6b7c93;
  outline: none;
  margin: 0 0 8px 0;
  color: #fff;
  background: #396af1;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
}

.management .unsplashviewer .image-result {
  cursor: pointer;
}

.management .unsplashviewer .results .image-result {
  width: 400px;
  margin: 0 10px 10px 0;
  height: auto;
  float: left;
}
.management .unsplashviewer .results .image-result img {
  width: 100%;
  height: auto;
  float: left;
}
.management .unsplashviewer .image-result:hover {
  opacity: 0.8;
}

.management button.general {
  padding: 6px 8px;
  border-radius: 5px;
  border: 1px solid #396af1;
  outline: none;
  margin: 0 0 8px 0;
  color: #fff;
  background: #396af1;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
}

.management .coverphotodetails .coverphoto {
  width: 300px;
  height: auto;
}

.management .tripexperthotelpicker .item {
  margin: 0 0 10px 0;
}

.management .tripexperthotelpicker .item .detail {
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
}

.management .tripexperthotelpicker .item .name {
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}

.management .tripexperthotelpicker .item .price {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
}

.management .tripexperthotelpicker .item .choose {
  outline: none;
  border: none;
  background: #6b7c93;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  border-radius: 3px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 5px 0 0;
  padding: 5px 5px 3px 5px;
}

.management .addform.hotel {
  float: left;
}
.management .googleplaceidfinder {
  float: left;
  margin: 0 0 0 20px;
  width: 300px;
}

.management .tripexperthotelpicker {
  float: left;
  margin: 0 0 0 20px;
  width: 340px;
}

.management .tripexperthotelpicker .suggestions {
  max-height: 800px;
  overflow: scroll;
}
@media only screen and (max-width: 500px) {
  .management .tripexperthotelpicker .suggestions {
    max-height: none;
  }
}
.management .hotelactions button {
  margin: 0 10px 0 0;
}

.management .hoteldetail .databucket {
  margin: 20px 0 0 0;
}

.management .hoteldetail .detail {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
}

.management .hoteldetail .expertreviews .reviewsingle {
  margin: 10px 0 20px 0;
}

.management .hoteldetail .expertreviews .reviewsingle .icon {
  width: 40px;
  float: left;
  margin: 4px 0 0 0;
}
.management .hoteldetail .expertreviews .reviewsingle .content {
  margin: 0 0 0 10px;
  width: 400px;
  float: left;
}

.management
  .hoteldetail
  .expertreviews
  .reviewsingle
  .content
  .publicationname {
  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
}

.management .hoteldetail .expertreviews .reviewsingle .content .reviewextract {
  font-size: 14px;
}

.management .googleplacepicker input {
  border: 1px solid #6b7c93;
}

.reviewlink a {
  color: #396af1;
  font-size: 12px;
}

/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */
/* USER DASHBOARD  Styles */
/* USER DASHBOARD  Styles */
/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */

/* Main UI */
.app-page .container {
  max-width: 90%;
  margin: 0 auto;
}
.app-page .main-window {
  margin-top: 0px;
}
.bar {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  border-bottom: 1px solid #eee;
  background: #fff;
}

.barspacer {
  height: 60px;
  width: 100%;
}

.bar .branding {
  float: left;
  color: #000;
  margin: 20px 0 0 0;
}

.bar .branding h1 {
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  line-height: 60px;
  color: #000;
  margin: 0;
  padding: 0;
}

.bar .nav {
  float: right;
  list-style: none;
  margin-bottom: 0;
}

.bar .nav .nav-item {
  position: relative;
  float: left;
  margin-bottom: 0;
}

.bar .nav .nav-item .nav-link {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: -0.5px;
  margin-left: 35px;
  text-decoration: none;
  line-height: 60px;
  color: #000;
}

.bar .nav .nav-item .nav-link:hover {
  border-bottom: 3px solid #f1396a;
  transition: border-bottom 0.8s ease;
}

.dashboard .bar .nav .nav-item .nav-link.dashboard,
.settings .bar .nav .nav-item .nav-link.settings {
  border-bottom: 3px solid #396af1;
}

/* TRIP REQUESTS */
.spacing {
  margin: 20px;
}

.dashboard .newrequestcontainer {
  margin-top: 50px;
}

.requestlink {
  color: #396af1;
}

.requestbox {
  margin: 30px 0 0 0;
}

.requestbox .title {
  font-size: 30px;
  font-weight: 900;
  letter-spacing: -1px;
}

.requestnew .requestprompt {
  font-size: 14px;
  text-align: left;
  line-height: 18px;
}

.requestnew .requestdescription {
  width: 300px;
  display: block;
  outline: none;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 10px 10px 10px;
  border-radius: 3px;
  margin: 0 0 20px 0;
  appearance: none;
  -webkit-appearance: none;
}

.requestempty .intro p {
  font-size: 16px;
  line-height: 20px;
}

.requestempty .featureblock {
  margin: 40px 0 0 0;
}

.requestempty .featureblock .featuretitle {
  font-size: 20px;
  font-weight: 900;
  letter-spacing: -1px;
  margin: 20px 0 0 0;
  text-transform: uppercase;
}

.requestempty .featureblock .feature {
  font-size: 14px;
  font-weight: 300;
  margin: 14px 0 0 0;
}

.requestempty .featureblock .feature span {
  border-bottom: 1px dotted #6b7c93;
}

.requestempty .getstartedblock {
  margin: 40px 0 80px 0;
}

.requestlistitem {
  width: 100%;
  margin: 0px 0 30px 0;
  background: #fff;
}

.requestlistitem .requestdescription {
  font-weight: 300;
  font-size: 20px;
  line-height: 18px;
}

.requestlistitem .requesttimeago {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.requestlistitem .requestlinkcontainer {
  margin: 0;
}

.settingscopyright {
  font-size: 12px;
}

/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */
/* CONTENT PAGE Styles */
/* CONTENT PAGE Styles */
/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */

/* Structure */
i {
  margin: 0;
  padding: 0;
}
.wrapper {
  width: 960px;
  margin: 0 auto;
}
@media only screen and (max-width: 1080px) {
  .wrapper {
    width: 90%;
  }
}

.center {
  text-align: center;
  margin: 0 auto;
  width: 350px;
}

.splashloader {
  margin-top: 100px;
  width: 100%;
  text-align: center;
}

.splash {
  margin-top: 200px;
  text-align: center;
}
@media only screen and (max-width: 500px) {
  .splash {
    margin-top: 80px;
  }
}

.headerbar {
  width: 960px;
  margin: 50px auto 0 auto;
}

.headerbar .branding {
  float: left;
}

.headerbar .navlinks {
  float: right;
}

.headerbar .navlinks .item {
  position: relative;
  float: left;
  margin: 0 0 0 40px;
}

.headerbar .navlinks .item .link {
  color: #283037;
  line-height: 24px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: -1px;
  border-bottom: 3px solid transparent;
}

.headerbar .navlinks .item .link:hover {
  border-bottom: 3px solid #f1396a;
  transition: border-bottom 0.8s ease;
}

.howitworks .headerbar .navlinks .item .howitworks,
.business .headerbar .navlinks .item .business,
.personal .headerbar .navlinks .item .personal,
.pricing .headerbar .navlinks .item .pricing,
.login .headerbar .navlinks .item .login {
  border-bottom-color: #396af1;
}

/* RESPONSIVE EDITS FOR NAVBAR */
@media only screen and (max-width: 500px) {
  .headerbar {
    width: 100%;
    margin: 20px auto 0 auto;
  }
  .headerbar .branding {
    margin: 0 0 0 20px;
  }
  .headerbar .navlinks {
    margin: 0 20px 0 0;
  }
  .headerbar .navlinks .item {
    margin: 0 0 0 20px;
  }
}

#header {
  margin: 20px auto 0 auto;
}

#footer {
  width: 100%;
  margin: 80px auto 20px auto;
  padding: 60px 0 0 0;
  border-top: 1px solid #eee;
}
@media only screen and (max-width: 500px) {
  #footer {
    padding-top: 30px;
  }
}

.footercols {
  margin: 0 auto 50px auto;
}

.footercols .col {
  width: 20%;
  margin-left: 20px;
  float: left;
}

.footercols .col.branding {
  width: 25%;
  margin-left: 0;
}

#footer .footersocialrow {
  margin: 40px 0 0 0;
}

@media only screen and (max-width: 500px) {
  .footercols .col {
    width: 100%;
    margin: 0 0 30px 0;
  }
}

.footercols .description {
  font-size: 13px;
  line-height: 17px;
  margin: 6px 38px 0 0;
  color: #999;
}

@media only screen and (max-width: 500px) {
  .footercols .branding {
    display: none;
  }
}

.footercols .col .colheader {
  font-size: 13px;
  line-height: 16px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: -0.4px;
  margin: 0 0 5px 0;
  padding: 0;
}

.footercols .col .colheader.branding {
  font-size: 18px;
  text-transform: none;
  letter-spacing: -0.6px;
}

.footercols .col .colheader i {
  margin: 0 24px 0 0;
}

.footercols .col .colitem {
  color: #999;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin: 4px 0 0 0;
  padding: 0;
}

.footercols .col .colheader a {
  color: #283037;
}

.footercols .col .colitem a {
  color: #999;
}

.footercols .col .colitem a:hover {
  color: #396af1;
  border-right: 12px solid #396af1;
  padding-right: 6px;
}

.footer_social_icon {
  color: #6b7c93;
  margin: 0 16px 0 0;
}

.footer_social_icon.twitter:hover {
  color: #00aced;
}
.footer_social_icon.facebook:hover {
  color: #3b5998;
}
.footer_social_icon.linkedin:hover {
  color: #0077b5;
}
.footer_social_icon.facebookmessenger:hover {
  color: #0084ff;
}
.footer_social_icon.youtube:hover {
  color: #ff0000;
}
.footer_social_icon.instagram:hover {
  color: #e518a1;
}
.footer_social_icon.medium:hover {
  color: #1e843a;
}

.copyright {
  margin: 60px 0 0 0;
}
@media only screen and (max-width: 500px) {
  .copyright {
    margin: 20px 0 0 0;
  }
}

.copyright p {
  font-size: 12px !important;
  line-height: 16px;
}

/* Recommendations page */

.productrecommendations {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

.productrecommendations h4 {
  font-size: 28px;
}

.productrecommendations p {
  font-size: 18px;
  line-height: 20px;
}

.productrecommendations a {
  color: #396af1;
}

.productrecommendations a:hover {
  color: #4267b2;
}

/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */
/* DYNAMIC PUBLIC PAGE Styles */
/* DYNAMIC PUBLIC PAGE Styles */
/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */

/* HOTEL PAGE -- PUBLIC  */

.hotel .detail {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 22px;
}

.hotel h2 {
  margin: 40px 0 0 0;
  padding: 0;
}

.hotel .hotelimageitem {
  background-size: cover;
  height: 600px;
}
@media only screen and (max-width: 700px) {
  .hotel .hotelimageitem {
    height: 250px;
  }
}

.hotel .imagecontrols {
  position: relative;
  top: -64px;
  z-index: 99;
  height: 0px;
  margin: 0px;
  padding: 0px;
}

.hotel .imagecontrols .imagecontrolbutton {
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -1px;
  padding: 8px 12px;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 12px;
  border-radius: 5px;
}

.hotel .imagecontrols .prev {
  float: left;
}

.hotel .imagecontrols .next {
  float: right;
}

.hotel .expertreviews .reviewsingle {
  margin: 10px 0 0 0;
  width: 33%;
  float: left;
}
@media only screen and (max-width: 700px) {
  .hotel .expertreviews .reviewsingle {
    width: 90%;
  }
}

.hotel .expertreviews .reviewsingle .icon {
  width: 40px;
  float: left;
  margin: 4px 0 0 0;
}
.hotel .expertreviews .reviewsingle .content {
  margin: 0 0 0 10px;
  float: left;
  width: 80%;
}

.hotel .expertreviews .reviewsingle .content .publicationname {
  font-size: 18px;
  line-height: 24px;
  font-weight: 900;
}

.hotel .expertreviews .reviewsingle .content .reviewextract {
  font-size: 14px;
}

.hotel .expertreviews .reviewlink a {
  color: #f1396a;
  font-size: 12px;
}

.hotel .te-attribution {
  margin: 10px 0 0 0;
}
.hotel .expertreviews .attribution a {
  font-size: 12px;
  margin: 0;
  line-height: 12px;
}

.hotel .expertreviews a {
  color: #f1396a;
}

.hotel .googlereviews .singlereview {
  width: 30%;
  float: left;
  padding: 0 30px 40px 0;
  margin: 20px 0 0 0;
}
@media only screen and (max-width: 700px) {
  .hotel .googlereviews .singlereview {
    width: 90%;
  }
}

.hotel .googlereviews .singlereview .attribution .profile {
  float: left;
  margin: 10px 0 0 0;
  width: 40px;
}
.hotel .googlereviews .singlereview .attribution .about {
  float: left;
  margin: 16px 0 0 12px;
}

.hotel .googlereviews .singlereview .attribution .authorname {
  font-size: 14px;
  line-height: 14px;
  margin: 0;
  padding: 0;
}

.hotel .googlereviews .singlereview .attribution .timeago {
  font-style: italic;
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  padding: 0;
}

.hotel .amenities .amenity {
  float: left;
  padding: 5px 10px;
  margin: 0 14px 14px 0;
  border: 1px solid #6b7c93;
  border-radius: 5px;
}

.react-rater-star.is-active,
.react-rater-star.is-active-half:before {
  color: #f1c039;
}

/* AIrfare  SEARCH PAGE */

.airportsearchcontainer {
  position: relative;
}

.airportsearch .suggestions {
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;

  z-index: 99;

  width: 300px;
  max-height: 300px;
  overflow: scroll;

  background: #fff;
  border: 2px solid #e2e2e2;
  border-radius: 3px;
}

.airportsearch input,
.flightsearch .DayPickerInput input {
  width: 270px;
  height: 44px;
  display: block;
  outline: none;
  border: 2px solid #e2e2e2;
  font-size: 18px;
  font-weight: 400;
  padding: 0px 15px 0px 15px;
  border-radius: 3px;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;

  z-index: 98;
}

.airportsearch .suggestions .suggestion {
  cursor: pointer;
  padding: 12px 12px 12px 12px;
  border-bottom: 1px solid #e2e2e2;
}

.airportsearch .suggestions .suggestion:hover {
  background-color: #eee;
}

.airportsearch .suggestions .suggestion .name {
  font-weight: 900;
  font-size: 14px;
  margin: 6px 0 0 0;
}

.airportsearch .suggestions .suggestion .location {
  font-weight: 300;
  font-size: 14px;
  margin: 2px 0 0 0;
}

.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.DayPicker-Day--selected {
  background: #396af1;
}

.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  background: #396af1;
}
.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  background: #396af1;
}

.management .infopanel h4 {
  margin: 20px 0 10px 0;
}

.management .infopanel .infoitem {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 22px;
}

/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */
/* Demo Page Styles */
/* Demo Page Styles */
/* Demo Page Styles */
/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */

.scheduler {
  width: 60%;
  height: 1000px;
  background: #fff;
  margin: 50px auto;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 600px) {
  .scheduler {
    width: 100%;
    margin-top: 20px;
  }
}

/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */
/* Personal Demo Page Styles */
/* Personal Demo Page Styles */
/* Personal Demo Page Styles */
/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */

.personaldemo {
  width: 40%;
  height: 550px;
  background: #fff;
  margin: 50px auto;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 600px) {
  .personaldemo {
    width: 100%;
    margin-top: -50px;
    box-shadow: none;
  }
}

/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */
/* Feature Page Styles */
/* Feature Page Styles */
/* Feature Page Styles */
/* 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥 */

.pagetitlecontainer {
  margin: 60px auto 60px auto;
  text-align: center;
  max-width: 500px;
}

.pagetitlecontainer h1 {
  font-size: 40px;
  line-height: 42px;
  letter-spacing: -1.5px;
  margin: 0;
  padding: 0;
}

.about {
  width: 50%;
}

.callout {
  width: 70%;
  margin: 80px auto 50px auto;
}

.callout.first {
  margin-top: 100px;
}

.callout h4,
.feature h4 {
  margin-bottom: 8px;
}

.callout p,
.feature p {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0px;
}

.feature {
  width: 60%;
  margin: 80px auto 50px auto;
}

.featureinner {
  margin-left: -2%;
}

.feature .description {
  width: 60%;
  float: left;
}

.feature .graphic {
  width: 34%;
  margin-top: 0px;
  margin-right: 4%;

  float: left;
}

.feature .graphic.right {
  float: right;
}

.feature .graphic img {
  width: 100%;
}

.examples {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.examples h3 {
  margin-top: 80px;
}

.examples p.featureexample {
  font-size: 20px;
}

.examples p.featureexample span {
  border-bottom: 3px solid #f1396a;
}

@media only screen and (max-width: 600px) {
  .callout {
    width: 90%;
  }
  .feature {
    width: 90%;
  }
  .feature .description {
    width: 100%;
  }
  .feature .graphic {
    width: 100%;
    margin: 0 auto;
  }
  .feature .graphic img {
    width: 50%;
    margin: 0 auto;
  }
  .examples {
    width: 90%;
    text-align: left;
  }
  .examples p.featureexample {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .examples p.featureexample span {
    border-bottom: 3px solid #f1396a;
  }
  .about {
    width: 90%;
    text-align: left;
  }
}

/* BLOG POSTS */
.bloggrid {
  width: 100%;
}

.bloggridinner {
  margin-top: 5%;
  margin-right: -5%;
}

.bloggrid .blogitem {
  width: 28%;
  margin-right: 5%;
  margin-bottom: 5%;
  float: left;
}

.bloggrid .blogitem .imgwrapper {
  height: 150px;
  overflow: hidden;
  border-radius: 3px;
}

.bloggrid .blogitem img {
  width: 100%;
  border-radius: 3px;
}

.bloggrid .blogitem .title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin: 6px 0 0 0;
  color: #283037;
}

.bloggrid .blogitem .snippet {
  font-size: 14px;
  line-height: 16px;
  margin: 4px 0 0 0;
  color: #283037;
}

.bloggrid .button.small {
  margin: 10px 0 0 0;
  padding: 6px 12px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .bloggridinner {
    margin-right: 0;
  }
  .bloggrid .blogitem {
    width: 100%;
    margin-bottom: 30px;
    margin-right: auto;
    float: left;
  }
}

.social_icon {
  display: inline-block;
  text-align: center;
  width: 44px;
  height: 44px;
  line-height: 44px;
  background: #000;
  margin: 0 10px 0 0;
  color: #fff;
  border-radius: 6px;
}

.social_icon.twitter {
  background-color: #00aced;
}

.social_icon.facebook {
  background-color: #3b5998;
}

.social_icon.linkedin {
  background-color: #0077b5;
}

.social_icon.youtube {
  background-color: #ff0000;
}

.social_icon.facebookmessenger {
  background-color: #0084ff;
}

.social_icon.medium {
  background-color: #000000;
}

.social_icon.instagram {
  background-color: #e518a1;
  background: -webkit-radial-gradient(
    50px 220px,
    #ffda75 18%,
    #ff3a49 43%,
    #ff3a49 50%,
    #e518a1 70%,
    #6634e1 100%
  );
}
